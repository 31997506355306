.form__title {
  margin: 15px auto;
  font-size: var(--fs-600);
  text-align: center;
}
.form__inputContainer {
  display: flex;
  gap: 20px;
}
.form__inputContainer > div {
  flex-basis: 100%;
}

.providedInformation__heading {
  margin-top: 20px;
  margin-inline: 10px;
  font-size: var(--fs-600);
}
.providedInformation__unorderedList {
  list-style: none;
  flex-basis: 47.5%;
  margin-inline: 10px;
}
.providedInformation__unorderedList li {
  margin-bottom: 15px;
}
.providedInformation__text {
  font-size: var(--fs-400);
}
.providedInformation__title {
  color: grey;
  font-size: var(--fs-200);
}
.providedInformation__container {
  display: flex;
  margin-block: 20px;
}
.providedInformation__editButton {
  height: 40px;
  width: 40px;
  background: #146d17;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
}
.modal__title {
  font-size: var(--fs-600);
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.listItem {
  display: flex;
  margin-block: 10px;
  font-size: var(--fs-400);
}
.input {
  width: 15px;
  margin-right: 10px;
}

.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
