.mainBodyContainer {
  flex: 0.8;
  height: 100%;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
}
.cardContainer {
  padding-inline: 10px;
  display: grid;
  place-content: center;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  margin-block: 20px;
  width: clamp(100px, 60vw + 120px, 1300px);
  max-width: 100%;
  margin-inline: auto;
}
.card {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(33, 63, 125, 0.1);
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--white-color);
  width: 100%;
}
.card__title {
  font-size: var(--fs-400);
  color: var(--black-color);
  font-weight: 500;
}
.card__value {
  font-size: var(--fs-800);
  color: var(--black-color);
  font-weight: 500;
}
