.container {
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.heading {
  text-align: center;
  /* margin-top: 20px; */
  margin-inline: 10px;
  font-size: var(--fs-600);
  font-weight: bold;
}
.subHeading {
  text-align: center;
  font-size: var(--fs-400);
  color: var(--grey-color);
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
/* ============ */
.title {
  font-size: var(--fs-600);
  font-weight: bold;
  text-align: center;
}
.subTitle {
  color: var(--grey-color);
  font-size: var(--fs-400);
  text-align: center;
}
.optionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 80px;
  width: 600px;
  margin-block: 10px;
  margin-inline: auto;
}
.option {
  flex-basis: 30%;
  cursor: pointer;
}
.option > img {
  width: 100%;
  height: 80px;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
