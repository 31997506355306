.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.courseTable {
  width: 100%;
  border: 1px solid grey;
  border-collapse: collapse;
  font-size: var(--fs-400);
  margin: 0px auto;
  max-width: initial;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding-inline: 50px;
  padding-block: 15px;
  border-radius: 10px;
  background-color: #fff;
}
.modal > h2 {
  margin-block: 15px;
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: 15px auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
