@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

/* =================== */
/* =================== */
/* html {
  font-size: 62.5%;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  line-height: 1.6;
}
.app {
  height: 100vh;
}
body::-webkit-scrollbar {
  display: none;
}

:root {
  /* --primary-color: #179bd7;
  --blue: #253b80; */
  --primary-color: #253b80;
  --blue: #179bd7;
  --white-color: #ffff;
  --black-color: #000000;
  --grey-color: #686868;
  --lightgreen: #e4f2fa;
  --fs-200: 12px;
  --fs-400: 14px;
  --fs-600: 16px;
  --fs-800: 22px;
  --fs-xl: clamp(40px, 10vw + 10px, 70px);
}
@media (min-width: 45em) {
  :root {
    --fs-200: 14px;
    --fs-400: 16px;
    --fs-600: 20px;
    --fs-800: 32px;
  }
}
h2 {
  font-size: var(--fs-600);
}
p {
  font-size: var(--fs-400);
}
/* =================== */
/* =================== */

/* =================== */
/* UTILITY CLASSES */
/* =================== */

.home-page-container {
  padding-block: min(8%, 50px);
  padding-inline: min(8%, 100px);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.page-container {
  padding-block: min(8%, 50px);
  padding-inline: min(8%, 100px);
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
}

.text-center {
  text-align: center;
}
.container {
  margin-inline: auto;
  width: min(90%, 0px);
  padding-block: min(8%, 50px);
}

#shapes {
  position: relative;
  width: 600px;
  height: 400px;
}
/* =================== */
/* =================== */

.forgot-password {
  text-align: center;
}

.forgot-password a {
  color: black;
  text-decoration: none;
}

.nav-container {
  position: fixed;
  width: 100%;
}

.logos-image img {
  width: 30px;
}

.dashboard-box {
  display: flex;
  padding-top: 60px;
}

.dashboard-sidebar {
  height: 100vh;
  width: 230px;
  background-color: black;
  color: white;
  padding: 70px 15px;
  line-height: 50px;
  margin-right: 20px;
}

.dashboard {
  padding: 20px;
}

.dashboard h2 {
  padding: 10px 0px;
}

.square {
  background-color: white;
  height: 100px;
  width: 190px;
  border-left: 5px solid green;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.3);
}
.dropdown-span {
  margin-right: 5px;
}
.dropdown-a {
  color: green;
  text-decoration: none;
}
.dropdown-div {
  display: flex;
}
.dropdownImg {
  display: inline;
  margin-top: 5px;
  height: 60%;
  width: 3%;
}
.dropbutton-h {
  display: inline;
}
.mini-box-shape {
  margin-top: 20px;
  display: flex;
}

.dashboard-mini-box p {
  font-size: 13px;
  margin: 7px 0px;
}

.dashboard-mini-box h4 {
  font-size: 15px;
}

.dashboard-mini-box a {
  text-decoration: none;
  color: green;
  font-size: 25px;
}

.max-box-text-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.max-box-table {
  display: flex;
}

/* Style Override for Phone Input Component*/
.special-label {
  top: -9px !important;
  left: 8px !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
