.container {
  /* display: flex;
    flex-direction: column;
    gap: 5px; */
}

.title {
  font-size: var(--fs-600);
  font-weight: bold;
  text-align: center;
}

.subTitle {
  font-size: var(--fs-400);
  color: var(--grey-color);
  text-align: center;
}

.button {
  margin: 10px auto;
}
.button:disabled {
  opacity: 0.5;
}

.anchorText {
  font-size: var(--fs-200);
  text-align: center;
}
.anchorText > a {
  color: var(--primary-color);
  text-decoration: none;
}
