.button {
  padding: 8px 10px;
  /* margin-block: 10px; */
  border-radius: 10px;
  text-transform: Capitalize;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--fs-400);
}
.button:disabled {
  opacity: 0.5;
}
.invert {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.primary {
  background: var(--primary-color) !important;
  color: white;
}
