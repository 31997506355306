.container {
}
.container h3 {
  font-size: var(--fs-600);
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}

.listItem {
  display: flex;
  margin-block: 10px;
}
.listItem label {
  font-size: var(--fs-400);
}
.input {
  width: 15px;
  margin-right: 10px;
}
.passport {
  width: 300px;
  height: 150px;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
