.container {
  padding-inline: 100px;
}
.heading {
  text-align: center;
  /* margin-top: 20px; */
  margin-inline: 10px;
  font-size: var(--fs-600);
  font-weight: bold;
}
.subHeading {
  text-align: center;
  font-size: var(--fs-400);
  color: var(--grey-color);
}

.optionsContainer {
  display: flex;
  gap: 40px;
  margin-block: 40px;
}
.optionsContainer > * {
  flex: 1 0 auto;
  min-width: 100px;
  cursor: pointer;
}
.optionsContainer > * img {
  width: 100px;
  height: 100px;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
