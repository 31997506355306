.title {
  text-align: center;
  font-size: var(--fs-600);
  font-weight: bold;
}
.documentContainer {
  display: flex;
  margin-block: 20px;
  gap: 20px;
}
.document {
  padding: 10px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.document > div {
  width: 100%;
}
.document input {
  cursor: pointer;
}

.document__title {
  text-align: center;
  font-weight: 500;
  font-size: var(--fs-400);
}
.document__text {
  text-align: center;
  font-size: var(--fs-200);
}

.buttonContainer {
  display: flex;
  gap: 20px;
  margin: auto;
  width: fit-content;
}

.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}

.forwardButton:disabled {
  opacity: 0.5;
}
