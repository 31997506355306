.mainBodyContainer {
  flex-grow: 1;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
}
.card {
  /* width: 90%;
    min-height: 300px; */
}
.container {
  width: 100%;
  padding-inline: 100px;
  padding-block: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.searchBar {
  display: flex;
  gap: 10px;
  width: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding-inline: 50px;
  padding-block: 15px;
  border-radius: 10px;
  background-color: #fff;
}
.modal > h2 {
  margin-block: 15px;
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.courseTable {
  width: 100%;
  border: 1px solid grey;
  border-collapse: collapse;
  font-size: var(--fs-400);
  margin: 0px auto;
  max-width: initial;
}
.courseTable tr:nth-child(even) {
  background-color: #dddddd;
}
.courseTable th,
.courseTable td {
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: left;
}
.courseTable tr > td:last-child {
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
}
/* tr > td:last-child {
    color: red;
    font-weight: bold;
    cursor: pointer;
} */
