.heading {
  margin-top: 20px;
  font-size: var(--fs-600);
  font-weight: bold;
}
.subHeading {
  font-size: var(--fs-400);
  color: var(--grey-color);
}
.listItem {
  display: flex;
  margin-block: 10px;
}
.listItem label {
  font-size: var(--fs-400);
}
.input {
  width: 15px;
  margin-right: 10px;
}
