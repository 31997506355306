.mainBodyContainer {
  flex-grow: 1;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
}
.card {
  min-height: 300px;
  overflow-x: auto;
}

.container {
  width: 100%;
  padding-inline: 100px;
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
