.card {
  position: relative;
  padding-inline: 50px;
  padding-block: 40px;
  border-radius: 10px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  /* flex-grow: 1; */
  background-color: var(--white-color);
  width: min(100%, 1200px);
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-inline: 10px;
  }
}
