.form__doubleColumn {
  display: flex;
  gap: 20px;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}

form.form__doubleColumn {
  margin-top: 20px;
  height: 100%;
}
.form__columnDirection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.form__fullWidth {
  width: 300px;
}
.form__halfWidth {
  width: 140px;
}
.form__uploadPhoto input {
  display: none;
}
.form__uploadPhoto p {
  margin-block: 4px;
}
.form__uploadPhoto label {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  width: 300px;
  height: 150px;
  background-color: rgb(228, 242, 250);
  border-radius: 15px;
  cursor: pointer;
}

.providedInformation__heading,
.form__heading {
  text-align: center;
  /* margin-top: 20px; */
  margin-inline: 10px;
  font-size: var(--fs-600);
  font-weight: bold;
}
.providedInformation__subHeading {
  text-align: center;
  font-size: var(--fs-400);
  color: var(--grey-color);
}
.providedInformation__unorderedList {
  list-style: none;
  flex-basis: 47.5%;
  margin-inline: 10px;
}
.providedInformation__unorderedList li {
  margin-bottom: 15px;
}
.providedInformation__unorderedList h4 {
  font-size: var(--fs-400);
}
.providedInformation__unorderedList p {
  color: grey;
  font-size: var(--fs-200);
}
.providedInformation__container {
  display: flex;
  margin-block: 20px;
  flex-wrap: wrap;
}
.providedInformation__editButton {
  height: 40px;
  width: 40px;
  background: #146d17;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
}
.modal h3 {
  font-size: var(--fs-600);
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
  color: #fff;
}

.listItem {
  display: flex;
  margin-block: 10px;
}
.listItem label {
  font-size: var(--fs-400);
}
.input {
  width: 15px;
  margin-right: 10px;
}

/* DOCUMENT SELECT STYLES*/
.documentSelect {
}
.documentSelect h1 {
  font-size: 24px;
  font-weight: 600;
}
.documentSelect__documentContainer {
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.documentSelect__documentContainer h4 {
  font-size: var(--fs-600);
  font-weight: 500;
}
.documentSelect__documentContainer h4 span {
  color: red;
}
.documentSelect__inputContainer {
  display: flex;
  gap: 50px;
}
.documentSelect__input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  position: relative;
}
.documentSelect__input label {
  background-color: var(--primary-color);
  padding: 7.5px;
  font-size: var(--fs-400);
  border-radius: 5px;
  flex-shrink: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  color: white;
  gap: 10px;
}
.documentSelect__input input {
  position: absolute;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}
.documentSelect__input p {
  font-size: var(--fs-200);
}
.documentSelect__input p:first-child {
  font-size: var(--fs-400);
}
