.mainBodyContainer {
  flex-grow: 1;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
  width: 100%;
}
.card {
  width: 90%;
  min-height: 300px;
}
.container {
  width: 100%;
  padding-inline: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepper__container {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

.card__width {
  width: 100%;
}
