.mainBodyContainer {
  flex: 1;
  height: 90vh;
  overflow-y: auto;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
}
.card {
  width: 90%;
  height: auto;
  overflow-y: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
}
.container {
  width: 100%;
  padding-inline: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-inline: 10px;
  }
}
.stepper__container {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

.card__width {
  width: 100%;
}

.backButton {
  background: transparent;
  padding-inline: 20px;
  color: #146d17;
  position: absolute;
  inset: 4% 85% 4% 0;
}

