.mainBodyContainer {
  flex-grow: 1;
}
.pageTitle {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid grey;
}
.card {
  min-height: 300px;
  overflow-x: auto;
}

.container {
  width: 100%;
  padding-inline: 100px;
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* PREVIEW MODAL */
.preview_modal {
  position: fixed;
  right: 0;
  inset-block: 0;
  width: 50vw;
  z-index: 15;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.title_container {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_btn {
  cursor: pointer;
  font-size: 20px;
}
.tab {
  display: flex;
  background-color: #f1f1f1;
}
.tab > * {
  flex-basis: 50%;
}

/* Style the buttons that are used to open the tab content */
.tab_links {
  background-color: inherit;

  border: none;
  outline: none;
  cursor: pointer;
  padding-block: 15px;
  transition: 0.3s;
  position: relative;
}

/* Change background color of buttons on hover */
.tab_links:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab_links.active::after {
  content: "";
  position: absolute;
  padding: 3px;
  inset-inline: 0;
  bottom: 0;
  background-color: var(--primary-color);
}

/* Style the tab content */
.tab_content {
  padding: 20px;
  flex-grow: 1;
}
.preview_modal__preview_info {
  display: flex;
}
.preview_modal__preview_info > * {
  flex-basis: 50%;
}
.preview_info__split_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button_container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
}
