.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
  height: 10vh;
  background-color: var(--white-color);
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.nav > li {
  list-style-type: none;
}
.link {
  display: flex;
  align-items: center;
  color: var(--dark-color);
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: var(--fs-400);
}
.link svg {
  font-size: var(--fs-600);
}
