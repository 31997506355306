.uploadPhoto {
  width: 300px;
  height: 150px;
  position: relative;
}
.uploadPhoto input {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.uploadPhoto label {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  height: 170px;
  background-color: rgb(228, 242, 250);
  border-radius: 15px;
  cursor: pointer;
}
.passport {
  width: 150px;
  height: 150px;
}
