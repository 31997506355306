table {
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  min-width: 900px;
  width: 100%;
  border-collapse: collapse;
  margin-inline: auto;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
thead {
  background-color: var(--primary-color);
}
thead > tr {
  border: none;
}
tr {
  border: 1px solid rgba(33, 63, 125, 0.3);
}
tbody tr:nth-of-type(even) {
  background-color: #d2ddee;
}
tbody tr:nth-of-type(odd) {
  background-color: #e9eef6;
}
th,
td {
  padding: 10px 15px;
  color: var(--primary-color);
  border: 1px solid rgba(33, 63, 125, 0.3);
  position: relative;
  /* white-space: nowrap; */
}

th {
  color: var(--white-color);
  font-size: var(--fs-400);
  font-weight: 500;
}
td {
  font-size: var(--fs-200);
  position: relative;
}

td > button {
  outline: none;
  border: 1px solid black;

  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.table__page-container {
  overflow: auto;
  max-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-inline: auto;
}
.no_data {
  width: 100%;
}
