.form__doubleColumn {
  display: flex;
  gap: 20px;
}
form.form__doubleColumn {
  margin-top: 20px;
}
.form__columnDirection {
  display: flex;
  flex-direction: column;
}
.form__fullWidth {
  width: 300px;
}
.form__halfWidth {
  width: 140px;
}
.form__uploadPhoto input {
  display: none;
}
.form__uploadPhoto p {
  margin-block: 4px;
}
.form__uploadPhoto label {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  width: 300px;
  height: 150px;
  background-color: rgb(228, 242, 250);
  border-radius: 15px;
  cursor: pointer;
}

.providedInformation__heading,
.form__heading {
  text-align: center;
  /* margin-top: 20px; */
  margin-inline: 10px;
  font-size: var(--fs-600);
  font-weight: bold;
}
.providedInformation__subHeading {
  text-align: center;
  font-size: var(--fs-400);
  color: var(--grey-color);
}
.providedInformation__unorderedList {
  list-style: none;
  flex-basis: 47.5%;
  margin-inline: 10px;
}
.providedInformation__unorderedList li {
  margin-bottom: 15px;
}
.providedInformation__unorderedList h4 {
  font-size: var(--fs-400);
}
.providedInformation__unorderedList p {
  color: grey;
  font-size: var(--fs-200);
}
.providedInformation__container {
  display: flex;
  margin-block: 20px;
}
.providedInformation__editButton {
  height: 40px;
  width: 40px;
  background: #146d17;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 550px);
  min-height: 40vh;
  z-index: 15;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
}
.modal h3 {
  font-size: var(--fs-600);
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}

.listItem {
  display: flex;
  margin-block: 10px;
}
.listItem label {
  font-size: var(--fs-400);
}
.input {
  width: 15px;
  margin-right: 10px;
}
