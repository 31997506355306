.container {
  display: flex;
  gap: 20px;
}
.form > ul {
  list-style: none;
}
.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.listItem > label {
  font-size: var(--fs-400);
}
.textInput {
  margin: 0;
}
.buttonContainer {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: 15px auto;
}
.backButton {
  background: transparent;
  color: var(--primary-color);
  outline: 1px solid var(--primary-color);
}
