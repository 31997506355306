.tab_container {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  width: 100%;
}

.tab_item {
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.active {
  border-bottom: 4px solid rgba(33, 63, 125, 0.3);
  position: relative;
  padding-bottom: 4px;
}
